/**
 * CompareOverlay
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class CompareOverlay
 * @param {object} props - Props
 */

import React, {useEffect, useState} from "react";
import Icon from "../../atoms/icon";
import {Link} from '../../atoms/link';
import Button from "../../atoms/button";
import {notificationOverlayClass, compareOverlayClass} from '../../layouts/default';

const CompareOverlay = (
    {
        items = [],
        handleItemClose: handleItemCloseCallback,
        handleClearContainer: handleClearContainerCallback,
        onToggleOpen: onToggleOpenCallback
        // ...props
    }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        onToggleOpenCallback && onToggleOpenCallback(isOpen);
        const main = document.querySelector('main');

        if (isOpen){
            // remove other overlay classes, if are present
            const classNameToRemove = [];
            main?.classList.forEach(className => {
                const regexp = new RegExp(notificationOverlayClass);
                if (className.match(regexp)){
                    classNameToRemove.push(className);
                }
            });
            classNameToRemove.forEach(item => main.classList.remove(item));

            // if there aren't compare-overlay class
            if (!main.classList.contains(compareOverlayClass)){
                main.classList.add(compareOverlayClass);
            }
        }else{
            main?.classList.remove(compareOverlayClass);
        }
    }, [isOpen])

    useEffect(() => {
        const main = document.querySelector('main');
        if (main){
            const observer = new MutationObserver(
                (mutationsList) => {
                    mutationsList.forEach((mutation = {}) => {
                        if (mutation?.attributeName && mutation.attributeName === 'class'){
                            const hasClass = mutation.target.classList.contains(compareOverlayClass);
                            setIsOpen(hasClass);
                        }
                    });
                }
            );
            observer.observe(main, {attributes: true});
        }
    }, []);

    const handleToggleOpen = event => {
        event.preventDefault();
        setIsOpen(!isOpen);
    }

    const handleItemClose = (event, item) => {
        handleItemCloseCallback && handleItemCloseCallback(event, item);
    };

    const handleClearContainer = (event) => {
        handleClearContainerCallback && handleClearContainerCallback(event);
    };

    return (
        <>
            <div
                className={`compare-overlay ${items.length === 0 ? 'compare-overlay--hidden' : ''} ${isOpen ? 'compare-overlay--open' : ''}`}>
                <div className="compare-overlay__body">
                    <div className="compare-overlay__items">
                        {items.length > 0 && items.map(({image, family, name, model}, idx) => {
                            return idx < 4 ? <div key={idx} className="compare-overlay__item">
                                <Link className="compare-overlay__item-close" onClick={event => handleItemClose(event, {image, family, name, model})}>
                                    <Icon
                                        className="storybook"
                                        color="white"
                                        name="symbols-plus"
                                    />
                                </Link>
                                <div className="compare-overlay__item-image"
                                     style={{backgroundImage: `url(${image})`}}>&nbsp;</div>
                                <div className="compare-overlay__item-family">{family}</div>
                                <div className="compare-overlay__item-name">{name}</div>
                                <div className="compare-overlay__item-model">{model}</div>
                            </div> : '';
                        })}
                    </div>
                </div>
                <div className="compare-overlay__title">
                    <Link onClick={handleToggleOpen}>
                        {!isOpen && `Confronta ${items.length} ${items.length === 1 ? 'prodotto' : 'prodotti'}`}
                        {
                            isOpen ?
                                <Icon
                                    color="white"
                                    name="arrow-down"
                                />
                                :
                                <Icon
                                    color="white"
                                    name="arrow-up"
                                />
                        }
                    </Link>
                    <span className="goto-compare">
                        {isOpen && <Button
                            className="secondary sm _15"
                            color="white"
                            type="arrow"
                            link="/compare"
                            label={`Confronta ${items.length} ${items.length === 1 ? 'prodotto' : 'prodotti'}`}
                        >
                        </Button>}
                    </span>
                </div>
                <div className="compare-overlay__mobile-body">
                    <div>
                        <span>Hai selezionato <strong>{items.length}</strong> {items.length === 1 ? 'prodotto' : 'prodotti'} per il confronto</span>
                        <Button
                            className="primary sm _15"
                            color="blue"
                            link="/compare"
                            label="Confronta"
                        />
                        <Button
                            className="secondary sm _15"
                            color="white"
                            label="Svuota tutto"
                            onClick={handleClearContainer}
                        />
                    </div>
                </div>
                <div className="compare-overlay__mobile-fab">
                    <Link onClick={handleToggleOpen}>
                        <Icon
                            className="storybook"
                            color="white"
                            name="symbols-compare"
                        />
                        {items.length}
                    </Link>
                </div>
            </div>
        </>
    );
}

export default CompareOverlay;
