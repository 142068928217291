import React from "react"
import { graphql, useStaticQuery } from 'gatsby';

import Layout from "../app/layouts/default"
import Seo from "../app/services/seo"

import Products from '../app/modules/products-list/index.js'
import Filters from "../app/modules/filters";
import CompareOverlay from "../app/components/compare-overlay";
import Helpers from "../app/helpers"


const Lavatrici = () => {


    // const filter = `777`;
    const query = useStaticQuery(
        graphql`
            {
                allBabelProdotto(
                    filter: {familiesIds: {}, primaryimage: {elemMatch: {imageType: {}}}, lineId: {eq: 777}}
                ) {
                    edges {
                        node {
                            lineId
                            key_prod
                            id
                            familiesIds {
                                title
                                id
                                triples {
                                    id
                                    image
                                    linkcall
                                    linkimg
                                    market_exception
                                    text
                                    titimg
                                    title
                                    video
                                }
                            }
                            brand_id
                            brandName
                            productName
                            primaryimage {
                                imageType
                                imageUrl
                            }
                            internal {
                                description
                                ignoreType
                                mediaType
                                content
                            }
                            triples {
                                id
                                image
                                keySellingPoint
                                linkcall
                                linkimg
                                market_exception
                                text
                                titimg
                                title
                                video
                            }
                            connectivity
                        }
                    }
                }
            }
        `
    );


    let products = query.allBabelProdotto.edges;

    products = Helpers.productsDataManipulation(products, true);

    const breadcrumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            label: 'Lavatrici a carica frontale'
        },
    ];

    const overlayItems = products.slice(0, 4).map((
        {
            node: {
                familiesIds,
                primaryimage,
                productName,
                productNameVerbose
            }
        }) => ({
            image: primaryimage.find(item => item?.imageType === '100')?.imageUrl,
            family: familiesIds?.map(item => item.title).join(', ') || '',
            name: productNameVerbose,
            model: productName
        }));

    return (
        <>
            <Layout
                headerActiveItem={1}
                breadcrumbs={breadcrumbs}
                reevooEmbeddable={"series:777"}

                sidebar
                sidebarTitle="Lavatrici a carica frontale"
                sidebarChildren={Filters}
                sidebarHasFilters

                overlay
                overlayComponents={[
                    { component: CompareOverlay, props: { items: overlayItems } }
                ]}
            >
                <Seo title="Lavatrici a carica frontale" keywords={[`gatsby`, `application`, `react`]} />
                <Products products={products} />
            </Layout>
        </>
    )
};

export default Lavatrici



